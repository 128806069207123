<template>
  <div>
    <section class="iq-style1 py-4">
      <b-container>
        <b-row class="text-start">
          <b-col lg="6" md="4" cols="12">
            <curva-title :title="$t('productDetails.findProduct')"></curva-title>
          </b-col>
          <b-col lg="6" md="8" cols="12" class="d-flex justify-content-start gap_1">
            <div class="headerInput m-0 flex-grow-1">
              <input type="text" class="form-control"
                     placeholder="code (example 1248)" v-model="searchText"
                     @keyup.enter="getProductByCode">
              <i class="fas fa-search"></i>
            </div>
            <b-button class="px-5 iq-border-radius-5" variant="warning"> {{$t('search.search')}} </b-button>
          </b-col>
        </b-row>
      </b-container>
    </section>
    <b-container v-if="product.hasOwnProperty('prod_name')">
      <b-row class="pt-5" >
        <b-col lg="5" class="mb-4">
<!--          <div class="product-img-find" :style="{ 'background-image': 'url('+ product.prod_img + ')' }"></div>-->
          <img :src="product.prod_img" class="w-100" :alt="product.prod_name" />
        </b-col>
        <b-col lg="7">
          <router-link :to="{name: 'productDetails', params: {id:product.prod_id}}">
          <div class="d-flex align-items-start justify-content-between">
            <h4 class="text-warning font-weight-bold">
              {{ product.prod_name }}
            </h4>
          </div>
          </router-link>
          <section>
            <p class="text-justify font-size-16 text-break" style="white-space: break-spaces;">
              {{product.prod_Dsc.replaceAll('|', '\n') }}</p>
          </section>
        </b-col>
      </b-row>
      <b-row>
        <b-table :items="product.prod_stock" class="curva-table curva-table-branch text-initial"
                 tbody-tr-class="curva-table-row">
          <template v-slot:cell(branches)="data1">
            <span  class="category_badge d-inline-block px-2 py-1 mx-1 iq-border-radius-5" v-for="(item , key) in
            data1.item.branches.split('-').splice(0,data1.item.branches.split('-').length
            -1)" :key="key">
            {{item}}
            </span>
          </template>
        </b-table>
<!--        {{product.prod_stock}}-->
      </b-row>
    </b-container>
    <p v-else class="text-center mt-4">
      {{ $t('empty') }}
    </p>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
// import axios from 'axios'
import searchServices from '../services/search.services'
export default {
  data () {
    return {
      product: {},
      searchText: ''
    }
  },
  methods: {
    getProductByCode () {
      searchServices.searchProducts({ id: this.searchText }).then((res) => {
        this.product = res.data
      })
      // axios.get(`https://www.curvaegypt.com/ar/functions/fetch_product.php?product_id=${this.searchText}&searchby=online`).then(res => {
      //   this.product = res.data
      // })
    }
  },
  created () {
    // this.getProductByCode()
  },
  mounted () {
    core.index()
  }
}
</script>
<style scoped>
.curva-table-branch thead tr th div{
  text-align: initial !important;
}
.product-img-find {
  width: 100%;
  height: 500px;
  background-size: cover;
  background-position: center;
}
</style>
